import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Title from "../components/title/subtitle"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const NotFoundPage = () => {
  useEffect(() => {
    gsap.fromTo(".page-head", { y: 20, opacity: 0 }, { y: 0, opacity: 1 })
    gsap.fromTo(
      ".post-content-body",
      { y: 20, opacity: 0 },
      { y: 0, opacity: 1, delay: 0.5 }
    )
  }, [])

  return (
    <Layout>
      <SEO
        title="404エラー"
        description="しょうのまきのホームページの404エラーのページです。東京でイラストデザイン制作やロゴデザイン、チラシ制作、フライヤー制作、パンフレットの制作、カード類の制作などの広告印刷物の制作などおこなっております。"
        keywords={[
          `イラスト制作`,
          `美容室の広告印刷物の制作`,
          `ロゴデザイン`,
          `チラシ制作`,
        ]}
      />
      <header className="page-head">
        <Title title="このページはご利用いただけません。" />
      </header>

      <article className="post-content post-content-width">
        <div className="post-content-body center">
          <p>リンクに問題があるか、ページが削除された可能性があります。</p>
        </div>
      </article>
    </Layout>
  )
}

export default NotFoundPage
